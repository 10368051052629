<template>
    <div class="overall-rating">
        <div class="ratings-container">
            <div class="ratings">
                <div class="ratings-val" :style="{ width: (product.rating_summary / 100 * 5) * 20 + '%' }"></div>
                <span class="tooltip-text">{{ (product.rating_summary / 100 * 5) }}</span>
            </div>
            <span class="rating-count">{{ (product.rating_summary / 100 * 5) }} out of 5</span>
        </div>
        <p>{{ product.review_count }} global rating</p>
        <div class="rating-details">
            <div class="rating-details-item">
                <div class="rating-details-name">5 Stars</div>
                <div class="rating-details-bar">
                    <div class="rating-details-bar-val" style="width: 80%;"></div>
                </div>
                <div class="rating-details-val">80%</div>
            </div>
            <div class="rating-details-item">
                <div class="rating-details-name">4 Stars</div>
                <div class="rating-details-bar">
                    <div class="rating-details-bar-val" style="width: 20%;"></div>
                </div>
                <div class="rating-details-val">20%</div>
            </div>
            <div class="rating-details-item">
                <div class="rating-details-name">3 Stars</div>
                <div class="rating-details-bar">
                    <div class="rating-details-bar-val" style="width: 5%;"></div>
                </div>
                <div class="rating-details-val">5%</div>
            </div>
            <div class="rating-details-item">
                <div class="rating-details-name">2 Stars</div>
                <div class="rating-details-bar">
                    <div class="rating-details-bar-val" style="width: 1%;"></div>
                </div>
                <div class="rating-details-val">1%</div>
            </div>
            <div class="rating-details-item">
                <div class="rating-details-name">1 Stars</div>
                <div class="rating-details-bar">
                    <div class="rating-details-bar-val" style="width: 0%;"></div>
                </div>
                <div class="rating-details-val">0%</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        product: {
            type: Object,
            default: () => ({})
        }
    }
}
</script>