<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div>
    <div class="product-details" v-if="productCache">
      <!-- <div class="d-flex justify-content-end py-3" v-if="productCache.brand">
                <img :src="productCache.brand.logo" :width="100" alt="brand">
            </div> -->
      <!-- <h1 class="product-title">{{ productName }}</h1> -->
      <div class="d-flex justify-content-between align-items-center gap-6 mb-1">
        <div class="details-filter-row details-row-size">
          <label for="quantity" class="text-dark">{{ $t("quantity") }}:</label>
          <quantity-input
            :product="productCache"
            @change-qty="changeQty"
          ></quantity-input>
        </div>
        <div>
          <div class="product-price mb-0" v-if="stockQty == 0" key="outPrice">
            <span class="out-price">${{ productCache.price.toFixed(2) }}</span>
          </div>

          <template v-else>
            <div class="product-price mb-0" v-if="regularPrice == finalPrice">
              {{ formatCurrency(finalPrice) }}
            </div>
            <template v-else>
              <div class="product-price mb-0 flex-column">
                <!-- v-if="productCache.variants.length == 0" -->
                <span class="old-price">{{
                  formatCurrency(regularPrice)
                }}</span>
                <span class="new-price">{{ formatCurrency(finalPrice) }}</span>
              </div>
              <!-- <div class="product-price" v-else>${{ minPrice.toFixed(2) }} - ${{ maxPrice.toFixed(2) }}</div> -->
            </template>
          </template>
        </div>
      </div>
      <div class="underline"></div>
      <div class="d-flex justify-content-between my-2">
        <div>
          <!-- <p class="success">{{ $t('view_everything_from_the_category') }}</p> -->
          <p class="success" v-if="productCache.stock_status === 'IN_STOCK'">
            {{ $t("availability_in_stock") }}
          </p>
          <p class="text-danger" v-else>{{ $t("not_available_in_stock") }}</p>
          <!-- <p class="text-danger">{{ productCache.__typename }}</p> -->
          <template v-if="colors.length > 0">
            <div class="details-filter-row details-row-size">
              <div v-if="productCache.__typename == 'ConfigurableProduct'">
                <div
                  v-for="parameter of productConfig.configurable_options"
                  :key="parameter.id"
                >
                  <div v-if="parameter.attribute_code == 'kleur'">
                    <p class="dark color-title">{{ parameter.label }}:</p>
                    <div class="color-variant">
                      <div
                        class="custom-variant"
                        v-for="opt of parameter.values"
                        :key="opt.value_index"
                      >
                        <input
                          type="radio"
                          :value="opt.value_index"
                          :id="`${parameter.attribute_code}-${opt.value_index}`"
                          :name="`${parameter.attribute_code}-select`"
                        />
                        <label
                          :for="`${parameter.attribute_code}-${opt.value_index}`"
                        >
                          <div
                            class="variantCirle-wrap"
                            :style="{
                              borderColor: getColor(opt.value_index),
                            }"
                          >
                            <div
                              class="variantCirle"
                              :style="{
                                backgroundColor: getColor(opt.value_index),
                              }"
                            >
                              <font-awesome-icon icon="check" />
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div v-else class="extra-options-wrap">
                    <label>{{ parameter.label }}:</label>
                    <div class="product-nav product-nav-dots">
                      <!-- <a href="#" :class="{ active: item.color == selectedVariant.color, disabled: item.disabled }"
                                                    :style="{ 'background-color': item.color }" v-for="(item, index) in colorArray"
                                                    :key="index" @click.prevent="selectColor(item)"></a> -->

                      <!-- :label="item.label" -->
                      <a
                        v-for="(item, index) in parameter.values"
                        :key="index"
                        :class="{
                          active:
                            item.swatch_data.value == selectedVariant.color,
                        }"
                        :style="{
                          'background-color': item.swatch_data.value,
                        }"
                        @click="
                          handleColorSwitch(
                            item,
                            parameter.index,
                            parameter.value_index
                          )
                        "
                      ></a>
                      <!-- @click.prevent="selectColor(item)" -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div>
                                <label>{{ $t('color') }}:</label>
                                <div class="product-nav product-nav-dots">
                                    <router-link to=""
                                        :class="{ active: item.color == selectedVariant.color, disabled: item.disabled }"
                                        :style="{ 'background-color': item.color }" v-for="(item, index) in colors"
                                        :key="index"></router-link>
                                </div>
                            </div> -->
            </div>
          </template>
        </div>
        <div class="offer-box" v-if="productCache.price_tiers.length > 0">
          <p>
            Bestel {{ productCache.price_tiers[0].quantity }} stuks voor
            <strong>{{
              formatCurrency(productCache.price_tiers[0].final_price.value)
            }}</strong>
            per stuk en
            <strong
              >bespaar
              {{ productCache.price_tiers[0].discount.percent_off }}%</strong
            >
          </p>
        </div>
      </div>
      <div class="cart-button-box">
        <button class="cart-button" @click="addToCart">
          <font-awesome-icon icon="fa-solid fa-shopping-cart" />
          {{ $t("add_to_cart") }}
        </button>
      </div>

      <div class="d-flex align-items-center">
        <a
          href="#"
          class="btn-product btn-wishlist justify-content-start"
          title="Wishlist"
          @click.prevent="updateWish()"
          key="notInWishlist"
          v-if="!isInWishlist"
        >
          <span>{{ $t("add_to_wishlist") }}</span>
        </a>
        <router-link
          to="/account/"
          class="btn-product btn-wishlist justify-content-start added-to-wishlist"
          title="Wishlist"
          key="inWishlist"
          v-else
        >
          <span>Go to Wishlist</span>
        </router-link>

        <div class="compare d-flex gap-1 pointer" @click="addCompare">
          <input type="checkbox" :value="isCompare" v-model="isCompare" />
          <span> {{ $t("compare") }}</span>
        </div>
      </div>

      <div class="underline"></div>

      <!-- <template v-if="productCache.variants.length > 0">
            <div class="details-filter-row details-row-size">
                <label for="size">Size:</label>
                <div class="select-custom">
                    <select name="size" id="size" class="form-control" v-model="selectedVariant.size"
                        @change="selectSize">
                        <option value="null">Select a size</option>
                        <option :value="item.size" v-for="(item, index) in sizeArray" :key="index">{{ item.size
                            }}
                        </option>
                    </select>
                </div>

                <a href="javascript:;" class="size-guide mr-4">
                    <i class="icon-th-list"></i>size guide
                </a>
                <a href="#" @click.prevent="clearSelection" v-if="showClear">clear</a>
            </div>
            <vue-slide-toggle :open="showVariationPrice">
                <div class="product-price">${{ selectedVariant.price ? selectedVariant.price.toFixed(2) : 0 }}
                </div>
            </vue-slide-toggle>
        </template> -->

      <!-- <div class="ratings-container">
            <div class="ratings">
                <div class="ratings-val" :style="{ width: productCache.ratings * 20 + '%' }"></div>
                <span class="tooltip-text">{{ productCache.ratings.toFixed(2) }}</span>
            </div>
            <span class="ratings-text mt-0">( {{ productCache.review }} Reviews )</span>
        </div> -->

      <div class="product-content">
        <p>
          <strong>{{ $t("Delivery_time") }}:</strong>
          {{ productCache.DeliveryTime.long }}
        </p>
        <div class="underline"></div>
        <ul style="list-style: inside">
          <li class="text-primary">Nu 5% korting met code: ACTIE</li>
          <li>Gratis verzending NL vanaf 5,-</li>
          <li>Gratis cado bij elke besteling</li>
        </ul>
        <div class="underline"></div>
        <p>{{ $t("loyality_points") }}</p>
        <div class="mb-1">
          <strong>{{ $t("product_information2") }}:</strong>
          <p>Model: {{ productCache.sku }}</p>
        </div>
        <p v-html="productCache.short_description.html" />

        <!-- <p class="text-right mt-1">
                <font-awesome-icon icon="fa-solid fa-share-nodes" class="text-primary" />
                E-mail naar een vriend
            </p> -->
        <b-link class="secondary" :href="`mailto:?body=${getCurrentUrl}`">
          <p class="text-right mt-1">
            <font-awesome-icon
              icon="fa-solid fa-share-nodes"
              class="text-primary"
            />
            {{ $t("email_to_friend") }}
          </p>
        </b-link>
      </div>

      <!-- <div class="product-details-action">
            <a href="#" class="btn-product btn-cart" @click.prevent="addCart(0)">
                :class="{'btn-disabled': !canAddToCart(product, quantity) || (productCache.variants.length > 0 && ! showVariationPrice) }"
                <span>add to cart</span>
            </a>

            <div class="details-action-wrapper">
                <a href="#" class="btn-product btn-wishlist" title="Wishlist"
                    @click.prevent="addToWishlist({ product: product })" key="notInWishlist">
                    v-if="! isInWishlist(product)"
                    <span>Add to Wishlist</span>
                </a>
                <router-link
                    to=""
                    class="btn-product btn-wishlist added-to-wishlist"
                    title="Wishlist"
                    v-else
                    key="inWishlist"
                >
                    <span>Go to Wishlist</span>
                </router-link>
            </div>
        </div>

        <div class="product-details-footer">
            <div class="product-cat w-100 text-truncate">
                <span>Category:</span>
                <span v-for="(cat, index) of productCache.category" :key="index">
                    <router-link to="">{{ cat.name }}</router-link>
                    <span>{{ index < productCache.category.length - 1 ? ',' : '' }}</span>
                    </span>
            </div>

            <div class="social-icons social-icons-sm">
                <span class="social-label">Share:</span>
                <a href="javascript:;" class="social-icon" title="Facebook" target="_blank">
                    <i class="icon-facebook-f"></i>
                </a>
                <a href="javascript:;" class="social-icon" title="Twitter" target="_blank">
                    <i class="icon-twitter"></i>
                </a>
                <a href="javascript:;" class="social-icon" title="Instagram" target="_blank">
                    <i class="icon-instagram"></i>
                </a>
                <a href="javascript:;" class="social-icon" title="Pinterest" target="_blank">
                    <i class="icon-pinterest"></i>
                </a>
            </div>
        </div> -->

      <!-- Cart Popup -->
      <dialog-box
        width="60%"
        title=""
        :isVisible="cartDialog"
        :handleClose="handleClose"
      >
        <template v-slot:default>
          <product-list-view
            :product="productCache"
            type="cart"
            @handleClose="handleClose"
          />

          <!-- related_products -->
          <!-- <product-section
            v-if="relatedProducts && relatedProducts.length > 0"
            type="similar_products"
            :products="relatedProducts"
          /> -->
          <add-to-card-poup-card
            v-if="relatedProducts && relatedProducts.length > 0"
            type="similar_products"
            :products="relatedProducts"
          />
          <!-- related_products -->

          <!-- new_in_the_range -->
          <!-- <product-section
            v-if="newInTheRange"
            type="new_in_the_range"
            :products="newInTheRange"
          /> -->
          <add-to-card-poup-card
            v-if="newInTheRange"
            type="new_in_the_range"
            :products="newInTheRange"
          />
          <!-- new_in_the_range -->
        </template>
      </dialog-box>
      <!-- Cart Popup -->

      <!-- Compare Popup -->
      <dialog-box
        width="45%"
        title=""
        :isVisible="compareDialog"
        :handleClose="handleCompareDialogClose"
      >
        <template v-slot:default>
          <product-list-view
            :product="productCache"
            type="compare"
            :heading="$t('product_added_comparison_list_msg')"
            @handleClose="handleCompareDialogClose"
          />
        </template>
      </dialog-box>
      <!-- Compare Popup -->
    </div>
    <div v-else>
      {{ $t("Product not found or out of stock") }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import QuantityInput from "@/esf_weert_mobilesupplies/core/components/elements/QuantityInput";
import ProductListView from "@/esf_weert_mobilesupplies/core/components/elements/products/ProductListView";
import DialogBox from "@/esf_weert_mobilesupplies/core/components/elements/DialogBox";
import AddToCardPoupCard from "@/esf_weert_mobilesupplies/core/components/elements/products/AddToCardPoupCard.vue";
import { isServer } from "@storefront/core/helpers";

export default {
  components: {
    QuantityInput,
    ProductListView,
    DialogBox,
    AddToCardPoupCard,
  },
  props: {
    productCache: {
      type: Object,
      default: () => null,
    },
    selectedConfigProduct: Object,
  },
  data: function () {
    return {
      variationGroup: [],
      selectableGroup: [],
      sizeArray: [],
      colorArray: [],
      selectedVariant: {
        color: null,
        colorName: null,
        price: null,
        size: null,
      },
      // maxPrice: this.productCache.price_range.maximum_price.regular_price.value,
      // minPrice: this.productCache.price_range.maximum_price.final_price.value,
      quantity: 1,
      cartDialog: false,
      compareDialog: false,
      colors: [
        {
          color: "#cc9966",
          color_name: "Brown",
        },
        {
          color: "#3399cc",
          color_name: "Blue",
        },
        {
          color: "#9966cc",
          color_name: "Purple",
        },
        {
          color: "#333333",
          color_name: "Black",
        },
        {
          color: "#669933",
          color_name: "Green",
        },
        {
          color: "#cc3333",
          color_name: "Red",
        },
        {
          color: "#999999",
          color_name: "Grey",
        },
        {
          color: "#c0c0c0",
          color_name: "Silver",
        },
        {
          color: "#EE7D21",
          color_name: "Orange",
        },
        {
          color: "#FEDC32",
          color_name: "Yellow",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
      productConfig: "product/getCurrentConfig",
      productBundles: "product/getCurrentBundle",
      selectedBundles: "product/getSelectedBundles",
      options: "product/getCurrentProductOptions",
    }),
    productName() {
      return this.selectedConfigProduct
        ? this.selectedConfigProduct.name
        : this.productCache.name;
    },
    isInWishlist() {
      return this.$store.getters["wishlist/isProductInWishlist"](
        this.productCache.sku
      );
    },
    newInTheRange() {
      return this.$store.getters["home/getHomeNewSofas"];
    },
    relatedProducts() {
      return this.$store.getters["product/getRelated"];
    },
    getCurrentUrl() {
      if (!isServer) {
        return window.location.href;
      }
      return "";
    },
    compareProducts() {
      return this.$store.getters["product/getProductCompare"];
    },
    isCompare() {
      return this.compareProducts.some(
        (product) => product.sku == this.productCache.sku
      );
    },
    currentChildSku() {
      return this.$store.getters["product/getCurrentChildSku"];
    },
    btnDisable() {
      if (this.productCache.__typename == "ConfigurableProduct") {
        const retval = this.currentChildSku;
        if (retval != null) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    regularPrice() {
      return this.selectedConfigProduct
        ? this.selectedConfigProduct.price_range.maximum_price.regular_price
            .value
        : this.productCache &&
            this.productCache.price_range.maximum_price.regular_price.value;
    },
    finalPrice() {
      return this.selectedConfigProduct
        ? this.selectedConfigProduct.price_range.maximum_price.final_price.value
        : this.productCache &&
            this.productCache.price_range.maximum_price.final_price.value;
    },
    stockQty() {
      return this.selectedConfigProduct
        ? this.selectedConfigProduct.stock_qty
        : this.productCache.stock_qty;
    },
  },
  created: function () {
    console.log("product/productCache =>", this.productCache);
    console.log("product/product =>", this.product);
    console.log("this.selectedConfigProduct =>", this.selectedConfigProduct);
    console.log("product/getCurrentProductOptions =>", this.ProductOptions);
    console.log("product/getCurrentConfig =>", this.productConfig);
    console.log("  this.selectedVariant", this.selectedVariant);
  },
  methods: {
    handleColorSwitch(item, parameterIndex, valueIndex) {
      console.log(
        {
          parameterIndex,
          valueIndex,
          Item: item.value_index,
          config: this.productConfig,
        },
        "productConfig"
      );

      // Find the matched variant based on the selected color
      const matchedVariant = this.productConfig.variants.find((variant) => {
        return variant.attributes.some(
          (attribute) => attribute.value_index === item.value_index
        );
      });

      if (matchedVariant) {
        this.productCache = { ...this.product, ...matchedVariant.product };
        this.$emit("update-product", this.productCache);
        return this.productCache;
      } else {
        console.log("No matching variant found");
        return null;
      }
    },

    selectColor: function (item) {
      if (item.color == this.selectedVariant.color) {
        this.selectedVariant = {
          ...this.selectedVariant,
          color: null,
          colorName: null,
          price: item.price,
        };
      } else {
        this.selectedVariant = {
          ...this.selectedVariant,
          color: item.swatch_data.value,
          colorName: item.label,
          price: item.price,
        };
      }
      // this.refreshSelectableGroup();
    },
    // selectSize: function () {
    //     if (this.selectedVariant.size == 'null') {
    //         this.selectedVariant = { ...this.selectedVariant, size: null };
    //     }
    //     this.refreshSelectableGroup();
    // },
    // clearSelection: function () {
    //     this.selectedVariant = {
    //         ...this.selectedVariant,
    //         color: null,
    //         colorName: null,
    //         size: null
    //     };
    //     this.refreshSelectableGroup();
    // },
    changeQty: function (current) {
      this.quantity = current;
    },
    getColor(value) {
      if (this.productCache.__typename == "ConfigurableProduct") {
        const parameter = this.productConfig.configurable_options.find(
          (element) => element.attribute_code === "kleur"
        );

        if (parameter != null) {
          if (this.productConfig.configurable_options.length === 1) {
            const variant = this.productConfig.variants.find(
              (el) => el.productCache.sku === value
            );

            const newVal = variant.attributes[0].value_index;
            const object = parameter.values.find(
              (element) => element.value_index == newVal
            );
            if (object != null) {
              return object.swatch_data.value;
            } else {
              return null;
            }
          } else {
            const object = parameter.values.find(
              (element) => element.value_index == value
            );
            return object.swatch_data.value;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    async addToCart() {
      this.cartDialog = true;
      switch (this.productCache.__typename) {
        case "SimpleProduct": {
          const item =
            '{data: {sku: "' +
            this.productCache.sku +
            '", quantity:' +
            this.quantity +
            "}}  ";
          let retval = await this.$store.dispatch("cart/addToCart", {
            type: this.productCache.__typename,
            item: item,
          });

          if (retval == false) {
            const msg = {
              type: "danger",
              title: this.$t("shopping_basket"),
              text: this.$t("not_add_product"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            // this.$bvModal.show("modal-1");
            this.$root.$emit("openCartPopup");
          }
          break;
        }
        case "ConfigurableProduct": {
          const item =
            '{ parent_sku: "' +
            this.productCache.sku +
            '" data: {sku: "' +
            this.currentChildSku +
            '", quantity:' +
            this.quantity +
            "}}  ";
          let retval = await this.$store.dispatch("cart/addToCart", {
            type: this.productCache.__typename,
            item: item,
          });
          if (retval == false) {
            const msg = {
              type: "danger",
              title: this.$t("add_to_cart"),
              text: this.$t("not_add_product"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            if (this.$gtm.enabled()) {
              window.dataLayer.push({
                event: "eec.add",
                ecommerce: {
                  add: {
                    products: [
                      {
                        name: this.productCache.name,
                        id: this.productCache.sku,
                        quantity: this.quantity,
                      },
                    ],
                  },
                },
              });
            }
            this.$root.$emit("openCartPopup");
          }
          break;
        }
        case "BundleProduct": {
          const selected_options =
            this.$store.getters["product/getSelectedBundlesOptions"];

          let items =
            "{ quantity: " +
            this.quantity +
            ' sku: "' +
            this.productCache.sku +
            '" selected_options: ' +
            JSON.stringify(selected_options) +
            " }";

          const retval = await this.$store.dispatch("cart/addProductToCart", {
            items: items,
          });

          if (this.$gtm.enabled()) {
            window.dataLayer.push({
              event: "eec.add",
              ecommerce: {
                add: {
                  products: [
                    {
                      name: this.productCache.name,
                      id: this.productCache.sku,
                      quantity: this.quantity,
                    },
                  ],
                },
              },
            });
            if (this.$gtm.ga4Enabled) {
              window.dataLayer.push({
                event: "add_to_cart",
                ecommerce: {
                  add: {
                    currency: "EUR",
                    value: this.productCache.final_price,
                    items: [this.getGTMItems()],
                  },
                },
              });
            }
          }
          if (retval == false) {
            const msg = {
              type: "danger",
              title: this.$t("add_to_cart"),
              text: this.$t("not_add_product"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            this.$root.$emit("openCartPopup");
          }
          break;
        }
        // case "GroupedProduct": {
        //     const selected_options =
        //         this.$store.getters["product/getSelectedBundlesOptions"];

        //     let items =
        //         "{ quantity: " +
        //         this.quantity +
        //         ' sku: "' +
        //         this.productCache.sku +
        //         '" selected_options: ' +
        //         JSON.stringify(selected_options) +
        //         " }";

        //     const retval = await this.$store.dispatch("cart/addProductToCart", {
        //         items: items,
        //     });

        //     if (this.$gtm.enabled()) {
        //         window.dataLayer.push({
        //             event: "eec.add",
        //             ecommerce: {
        //                 add: {
        //                     products: [
        //                         {
        //                             name: this.productCache.name,
        //                             id: this.productCache.sku,
        //                             quantity: this.quantity,
        //                         },
        //                     ],
        //                 },
        //             },
        //         });
        //         if (this.$gtm.ga4Enabled) {
        //             window.dataLayer.push({
        //                 event: "add_to_cart",
        //                 ecommerce: {
        //                     add: {
        //                         currency: "EUR",
        //                         value: this.productCache.final_price,
        //                         items: [this.getGTMItems()],
        //                     },
        //                 },
        //             });
        //         }
        //     }
        //     if (retval == false) {
        //         const msg = {
        //             type: "danger",
        //             title: this.$t("add_to_cart"),
        //             text: this.$t("not_add_product"),
        //         };
        //         this.$store.dispatch("messages/sendMessage", { message: msg });
        //     } else {
        //         this.$root.$emit("openCartPopup");
        //     }
        //     break;
        // }
        default:
          break;
      }
      this.quantity = 1;
    },
    updateWish() {
      if (this.isInWishlist) {
        this.$store.dispatch("wishlist/removeProductFromWishlist", {
          sku: this.productCache.sku,
        });
      } else {
        if (this.productCache.__typename == "SimpleProduct") {
          this.$store.dispatch("wishlist/addProductToWishlist", {
            sku: this.productCache.sku,
            parentSku: null,
          });
        } else {
          const childSku = this.currentChildSku;
          if (childSku == null) {
            const msg = {
              type: "danger",
              title: this.$t("wishlist error"),
              text: this.$t("wishlist_select_options"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            this.$store.dispatch("wishlist/addProductToWishlist", {
              sku: childSku,
              parentSku: this.productCache.sku,
            });
            const msg = {
              type: "success",
              title: "Add wishlist",
              text: this.$t("add_product_wishlist"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          }
        }
      }
    },
    formatCurrency(amount) {
      return this.$helpers.formatCurrency(amount);
    },
    handleClose() {
      this.cartDialog = false;
    },
    handleCompareDialogOpen() {
      this.compareDialog = true;
    },
    handleCompareDialogClose() {
      this.compareDialog = false;
    },
    addCompare: function () {
      if (this.isCompare) {
        this.$store.dispatch("product/removeCompareProduct", {
          sku: this.productCache.sku,
        });
        const msg = {
          type: "success",
          title: "Success",
          text: "Product removed from compare list",
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
        return;
      }
      if (this.compareProducts.length >= 2) {
        const msg = {
          type: "danger",
          title: "Error",
          text: "You can only compare 2 products",
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
        return;
      }
      this.$store.dispatch("product/addCompareProduct", {
        sku: this.productCache.sku,
      });
      this.handleCompareDialogOpen();
    },
  },
};
</script>