<template>
  <div class="container">
    <div class="section-title mt-3">
      <div>
        <p class="title">
          <span>{{ $t(type) }}</span>
        </p>
      </div>
    </div>

    <div class="products">
      <VueSlickCarousel v-bind="settings">
        <div v-for="(product, index) in products" :key="index" class="gap-4">
            <add-to-card-home :product="product"></add-to-card-home>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import AddToCardHome from "@/esf_weert_mobilesupplies/core/components/elements/products/AddToCardHome.vue";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  components: {
    AddToCardHome,
    VueSlickCarousel,
  },
  props: {
    products: Array,
    type: String,
  },
  data: function () {
    return {
      settings: {
        // dots: true,
        arrows: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      },
    };
  },
  mounted() {
    console.log(this.products, "New_In_The_Range_Product");
  },
};
</script>
